import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiHtml5,
  DiGit,
  DiJava,
  DiCss3Full
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiPostgresql,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <blockquote className="blockquote mb-0">
        <p>Javascript</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <blockquote className="blockquote mb-0">
          <p>NodeJS</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <blockquote className="blockquote mb-0">
          <p>React</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <blockquote className="blockquote mb-0">
          <p>MongoDB</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
        <blockquote className="blockquote mb-0">
          <p>HTML5</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCss3Full />
        <blockquote className="blockquote mb-0">
          <p>CSS3</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
        <blockquote className="blockquote mb-0">
          <p>NextJS</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <blockquote className="blockquote mb-0">
          <p>Git</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <blockquote className="blockquote mb-0">
          <p>Firebase</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
        <blockquote className="blockquote mb-0">
          <p>Redis</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <blockquote className="blockquote mb-0">
          <p>PostgreSQL</p>
        </blockquote>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <blockquote className="blockquote mb-0">
          <p>Java</p>
        </blockquote>
      </Col>
    </Row>
  );
}

export default Techstack;
