import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import icoess from "../../Assets/Projects/icoess.png";
import tubelight from "../../Assets/Projects/tubelight.png";
import bytecart from "../../Assets/Projects/bytecart.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tubelight}
              isBlog={false}
              title="YouTube Clone"
              description="The YouTube Clone is a fully functional video streaming platform that replicates the core features of YouTube, developed using React.js and powered by RapidAPI. The application allows users to search for videos, view video details, and watch videos seamlessly within the app. It is designed to provide an intuitive and user-friendly interface, similar to the original YouTube experience."
              ghLink="https://github.com/Jagdishk7/Youtube-Clone"
              demoLink="https://tubelightytclone.netlify.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bytecart}
              isBlog={false}
              title="ByteCart"
              description="This Advanced E-Commerce Platform is a comprehensive, feature-rich online shopping website built using the MERN stack (MongoDB, Express, React, Node.js). It offers a seamless shopping experience for customers and a powerful admin panel for managing products, orders, and users."
              ghLink="https://github.com/Jagdishk7/EcommerceApp"
              demoLink="https://bytecart.netlify.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={icoess}
              isBlog={false}
              title="IT Company Website"
              description="This IT Company Website is a professional, dynamic, and fully responsive platform built using the MERN stack (MongoDB, Express, React, Node.js). It showcases the services and expertise of an IT company, providing visitors with a smooth and interactive browsing experience."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://icoesolution.com/"              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
